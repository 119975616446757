
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinEmployeeEach from '@/mixins/mixinEmployeeEach';

@Component({
  components: {
  }
})
export default class Main extends Mixins(mixinEmployeeEach) {
  @Prop() index: number;
  @Prop() is_spouce: boolean;

  get dependant_num() {
    if (this.is_demand_admit_page) {
      return Object.keys(this.ch_dependants).length;
    } else {
      return this.m.dependant_num;
    }
  }

  get dependant() {
    return this.dependants[this.dependant_id];
  }
  get tmp_dependant() {
    if (this.is_demand_admit_page) {
      return this.ch_dependants[this.index];
    } else if (this.is_special_page) {
      return this.m.join_dependants[this.index];
    } else {
      return this.dependant;
    }
  }
  get old_dependant() {
    if (this.tmp_dependant.dependant_id) {
      var dependant = this.family.dependants[this.tmp_dependant.dependant_id];
      if (dependant) {
        return dependant;
      } else {
        return {};
      }
    } else {
      return {};
    }
  }

  get is_disp_old_spouce_housemate() {
    return this.is_spouce && this.tmp_dependant.is_housemate == 0 && this.is_over_seventy;
  }
  get is_over_seventy() {
    return util.age(this.tmp_dependant.birth_date, util.obj2year()+"-01-01") >= 69;
  }
}
