
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinEmployeeEach from '@/mixins/mixinEmployeeEach';
import CommonProgress from "@/components/common/Progress.vue";
import CommonButton from "@/components/common/Button.vue";
import DependantInputBaseinfo from "@/components/dependant/input/Baseinfo.vue";
import DependantDisplayBaseinfo from "@/components/dependant/display/Baseinfo.vue";
import DependantInputSelfinfo from "@/components/dependant/input/Selfinfo.vue";
import DependantDisplaySelfinfo from "@/components/dependant/display/Selfinfo.vue";
import CommonBigbutton from "@/components/common/Bigbutton.vue";

@Component({
  components: {
    CommonProgress,
    CommonButton,
    DependantInputBaseinfo,
    DependantDisplayBaseinfo,
    DependantInputSelfinfo,
    DependantDisplaySelfinfo,
    CommonBigbutton,
  }
})
export default class Main extends Mixins(mixinEmployeeEach) {
  created() {
    if (this.is_add) {
      this.m.setDependant({dependant: {
        employee_id: this.employee_id, 
        name: null, 
        kana: null, 
        birth_date: null, 
        mynum: null, 
        dependant_relationship_id: null, 
        is_housemate: 1, 
        disability_type: 0, 
        income: 0, 
        other_income: 0, 
        is_not_live: 0, 
        is_child_allowance: 0, 
        is_special_disability_housemate: 1,
        is_old_spouce_housemate: null,
        is_international_student: 0,
        address: null,
        disability_reason: null,
        not_live_money: null,
      }});
    }
  }
  get dependant() {
    return this.dependants[this.dependant_id];
  }

  edit_dependant() {
    this.tmp_dependant = util.deep_copy(this.dependant);
    this.m.editDependant();
  }
  update_dependant() {
    this.m.updateDependant({employee_id: this.employee_id, dependant_id: this.dependant_id, is_spouce: this.employeely.is_spouce == 1});
  }
  create_dependant() {
    this.m.createDependant({employee_id: this.employee_id, is_spouce: this.employeely.is_spouce == 1});
  }

  get tmp_dependant() {
    return this.m.dependant;
  }

  set tmp_dependant(val) {
    this.m.setDependant({dependant: val});
  }

  get is_add() {
    return this.dependant_id == 0;
  }
}
