
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinEmployeeEach from '@/mixins/mixinEmployeeEach';
import global from "@/vuex/company/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/company/manager";
import header from "@/vuex/header";
import Earning from "@/entities/Earning";
import CommonText from "@/components/common/Text.vue";
import CommonInfobutton from "@/components/common/Infobutton.vue";
import CommonRadio from "@/components/common/Radio.vue";
import CommonTextarea from "@/components/common/Textarea.vue";

@Component({
  components: {
    CommonText,
    CommonInfobutton,
    CommonRadio,
    CommonTextarea,
  }
})
export default class Main extends Mixins(mixinEmployeeEach) {
  @Prop() index: number;


  get tmp_dependant() {
    if (this.is_special_page) {
      return this.m.join_dependants[this.index];
    } else {
      return this.m.dependant;
    }
  }
  set tmp_dependant(val) {
    if (this.is_special_page) {
      this.m.setJoinDependants({index: this.index, dependant: val});
    } else {
      this.m.setDependant({dependant: val});
    }
  }

  get income_sum() {
    const earning = new Earning();
    return earning.income2earning(Number(this.tmp_dependant.income)) + Number(this.tmp_dependant.other_income);
  }
}
