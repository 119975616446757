
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinEmployeeEach from '@/mixins/mixinEmployeeEach';
import Earning from "@/entities/Earning";
import CommonInfobutton from "@/components/common/Infobutton.vue";

@Component({
  components: {
    CommonInfobutton,
  }
})
export default class Main extends Mixins(mixinEmployeeEach) {
  @Prop() index: number;

  get dependant_num() {
    if (this.is_demand_admit_page) {
      return Object.keys(this.ch_dependants).length;
    } else {
      return this.m.dependant_num;
    }
  }

  get dependant() {
    return this.dependants[this.dependant_id];
  }
  get tmp_dependant() {
    if (this.is_demand_admit_page) {
      return this.ch_dependants[this.index];
    } else if (this.is_special_page) {
      return this.m.join_dependants[this.index];
    } else {
      return this.dependant;
    }
  }
  get old_dependant() {
    if (this.tmp_dependant.dependant_id) {
      var dependant = this.family.dependants[this.tmp_dependant.dependant_id];
      if (dependant) {
        return dependant;
      } else {
        return {};
      }
    } else {
      return {};
    }
  }
  
  get income_sum() {
    const earning = new Earning();
    return earning.income2earning(Number(this.tmp_dependant.income)) + Number(this.tmp_dependant.other_income);
  }
  get old_income_sum() {
    if (this.old_dependant.id) {
      const earning = new Earning();
      return earning.income2earning(Number(this.old_dependant.income)) + Number(this.old_dependant.other_income);
    } else {
      return null;
    }
  }
}
