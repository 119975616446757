
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinEmployeeEach from '@/mixins/mixinEmployeeEach';
import global from "@/vuex/company/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/company/manager";
import header from "@/vuex/header";
import CommonButton from "@/components/common/Button.vue";
import CommonText from "@/components/common/Text.vue";
import CommonDate from "@/components/common/Date.vue";
import CommonSelect from "@/components/common/Select.vue";
import CommonRadio from "@/components/common/Radio.vue";

@Component({
  components: {
    CommonButton,
    CommonText,
    CommonDate,
    CommonSelect,
    CommonRadio,
  }
})
export default class Main extends Mixins(mixinEmployeeEach) {
  @Prop() index: number;
  @Prop() is_spouce: boolean;


  get tmp_dependant() {
    if (this.is_special_page) {
      return this.m.join_dependants[this.index];
    } else {
      return this.m.dependant;
    }
  }
  set tmp_dependant(val) {
    if (this.is_special_page) {
      this.m.setJoinDependants({index: this.index, dependant: val});
    } else {
      this.m.setDependant({dependant: val});
    }
  }

  delete_dependant() {
    var index = this.m.dependant_num > 1 ? (this.index + 1) : '';
    dialog.openConfirmDialog({msg: '扶養親族'+index+'を削除してよろしいですか？', cancel_button: '削除しない', exec_button: '削除する', func: this.delete_dependant_process, args: {}});
  }
  delete_dependant_process({}) {
    this.m.setDependantNum({dependant_num: this.m.dependant_num - 1});
    this.m.deleteJoinDependants({index: this.index});
  }

  get is_disp_old_spouce_housemate() {
    return this.is_spouce && this.tmp_dependant.is_housemate == 0 && this.is_over_seventy;
  }
  get is_over_seventy() {
    return util.age(this.tmp_dependant.birth_date, util.obj2year()+"-01-01") >= 69;
  }
}
